import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useDropzone } from 'react-dropzone';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { SliderCard } from 'smart-react';
import {
  buildNotification,
  GenerateNotification,
  Loader,
  TextInput,
} from 'smart-react';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { ASSISTANTS_TYPES } from '../../../../constants/applicationConstants';
import { initiateLoginRequest } from '../../../SmartChat/Services/BlueYonderChatService';
import generateSessionKeyFunction from '../../../../Utils/Common/generateSessionKey';
import { Error } from '@progress/kendo-react-labels';
import './AssistantForm.scss';
import { useAuth } from '../../../Core/Context/AuthContext';

const AssistantForm = ({ assistant, actions, title }) => {
  const {
    assistantName,
    setAssistantName,
    assistantInstructions,
    setAssistantInstructions,
    droppedFiles,
    handleDeleteFile,
    setDroppedFiles,
    selectedFiles,
    templates,
    selectedOption,
    setSelectedOption,
    setAssistantType,
    assistantType,
    otherAssistantData,
    isUpdate,
  } = assistant;
  const { handleCancel, handleSubmit, handleSlide } = actions;
  const { user } = useAuth(); // Get logout and token using Auth Context
  // Filter assistant types based on user role
  const filteredAssistantTypes = user?.full_name?.toLowerCase().includes('admin')
    ? ASSISTANTS_TYPES
    : ASSISTANTS_TYPES.filter(type => type.value !== 'Custom');
  const [selectedType, setSelectedType] = useState({
    text: 'Please Select',
    value: '',
  });
  const [endpoint, setEndpoint] = useState('');
  const [serverid, setServerId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tenantKey, setTenantKey] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [BYResponse, setBYResponse] = useState('');
  const [serverOptions, setServerOptions] = useState('');
  const [sessionKey, setSessionKey] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [customUrl, setCustomUrl] = useState('');

  useEffect(() => {
    if (isUpdate) {
      setSelectedType({ text: assistantType, value: assistantType });
      setEndpoint(otherAssistantData?.endpoint);
      setServerId(otherAssistantData?.serverid);
      setUsername(otherAssistantData?.username);
      setPassword(otherAssistantData?.password);
      setTenantKey(otherAssistantData?.sa_tenant_key);
      setSessionKey(otherAssistantData?.session_key);
      setSessionKey(otherAssistantData?.assistant_id);
      setCustomUrl(otherAssistantData?.url);
    }
  }, [isUpdate, selectedOption, otherAssistantData]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setAssistantType(event.target.value.value);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setAssistantInstructions(event.target.value.description);
  };

  const validateOpenAIFields = () => {
    let isValid = true;
    let tempErrors = {};
    if (assistantName?.trim() === '') {
      tempErrors.assistantName = 'Assistant Name is required!';
      isValid = false;
    }
    if (assistantInstructions?.trim() === '') {
      tempErrors.assistantInstructions = 'Instructions are required!';
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const validateSmartRagFields = () => {
    let isValid = true;
    let tempErrors = {};
    if (assistantName?.trim() === '') {
      tempErrors.assistantName = 'Assistant Name is required!';
      isValid = false;
    }
    if (droppedFiles.length === 0 && selectedFiles.length === 0) {
      tempErrors.droppedFiles = 'Atleast 1 File is Required for this type';
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };
  const validateCustomFields = () => {
    let isValid = true;
    let tempErrors = {};
    if (assistantName?.trim() === '') {
      tempErrors.assistantName = 'Assistant Name is required!';
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const validateBlueYonderFields = () => {
    let isValid = true;
    let tempErrors = {};

    if (endpoint?.trim() === '') {
      tempErrors.endpoint = 'Endpoint is required!';
      isValid = false;
    }
    if (serverid?.trim() === '') {
      tempErrors.serverid = 'Server ID is required!';
      isValid = false;
    }
    if (username?.trim() === '') {
      tempErrors.username = 'Username is required!';
      isValid = false;
    }
    if (password?.trim() === '') {
      tempErrors.password = 'Password is required!';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleFormSubmit = async () => {
    let mappedData;

    // Validate Assistant Type
    if (selectedType.value?.trim() === '') {
      setErrors({ ...errors, assistantType: 'Assistant Type is required!' });
      return;
    }

    // Handling different assistant types
    if (selectedType.value === 'OpenAI') {
      if (validateOpenAIFields()) {
        handleSubmit(); // Call handleSubmit for OpenAI
      }
    }

    if (selectedType.value === 'SmartAI') {
      if (validateSmartRagFields()) {
        handleSubmit(otherAssistantData); // Pass otherAssistantData for SmartAI
      }
    }

    if (selectedType.value === 'Custom') {
      // Create the formData with all necessary fields
      if (validateCustomFields()) {
        const formData = {
          custom: true,
          url: customUrl,
        };
        handleSubmit(formData);
      }
    }

    if (selectedType.value === 'BlueYonder') {
      // Check if BYResponse is available, else initiate login if needed
      if (!BYResponse && !isUpdate) {
        let tempErrors = {};

        // Validate Tenant Key and Assistant ID
        if (tenantKey?.trim() === '') {
          tempErrors.tenantKey = 'Tenant Key is required!';
        }
        if (assistantId?.trim() === '') {
          tempErrors.assistantId = 'Assistant ID is required!';
        }

        // If validation fails, show errors and stop submission
        if (Object.keys(tempErrors).length > 0) {
          setErrors(tempErrors);
          return;
        }

        // Generate Session Key and set loading state
        setSessionKey(generateSessionKeyFunction());
        setIsLoader(true);

        try {
          // Request login to BlueYonder API
          const initiateLogin = await initiateLoginRequest({
            session_key: sessionKey,
            command: '',
            assistant_id: assistantId,
            sa_tenant_key: tenantKey,
          });

          if (initiateLogin?.payload && initiateLogin?.payload === 401) {
            GenerateNotification(
              buildNotification({
                title: 'Details are not valid!',
                description: '',
                style: 'error',
              }),
              NOTIFICATION_TYPES.APP,
              EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
            );
            setIsLoader(false);
            return;
          }

          // Map response data to form fields
          mappedData = initiateLogin.payload.metadata.reduce(
            (acc, key, index) => {
              acc[key] = initiateLogin.payload.values[0][index];
              return acc;
            },
            {}
          );

          // Extract necessary data from the response
          const AssistantUrlMetadata = mappedData.AssistantUrlMetadata;
          const serverIds = JSON.parse(
            AssistantUrlMetadata.split('serverid:')[1]
          ); // Extract server IDs from metadata
          const oogyUrl = JSON.parse(mappedData.Data).oogyUrl; // Extract oogyUrl from Data field

          // Set state based on response
          setAssistantName(mappedData?.AssistantName);
          setServerOptions(serverIds);
          setEndpoint(oogyUrl);
          setBYResponse(mappedData);
          setIsLoader(false);
        } catch (error) {
          setIsLoader(false);
          console.error('Error during initiate login:', error);
        }
      } else {
        // Validate BlueYonder fields before submitting
        if (!validateBlueYonderFields()) {
          return;
        }

        // Create the formData with all necessary fields
        const formData = {
          endpoint, // Extracted from oogyUrl
          serverid, // Selected from server options
          username, // Provided by user in the login form
          password, // Provided by user in the login form
          session_key: sessionKey, // Generated session key
          sa_tenant_key: tenantKey, // Tenant key or bearer token
          assistant_id: isUpdate
            ? otherAssistantData?.assistant_id
            : BYResponse?.AssistantId, // Assistant ID
          demo: false, // Assuming false for demo, can be updated if needed
          tenant_id: isUpdate
            ? otherAssistantData?.tenant_id
            : BYResponse?.TenantId, // Conditional assignment for TenantId
          openai_api_key: isUpdate
            ? otherAssistantData?.openai_api_key
            : BYResponse?.LlmKey, // Conditional assignment for OpenAI API key
          org_id: BYResponse?.OrganizationName,
          llm: isUpdate ? otherAssistantData?.llm : BYResponse?.Model, // Conditional assignment for Model
        };

        // Submit the form data
        handleSubmit(formData);
      }
    }
  };

  const getAcceptedFileTypes = () => {
    if (selectedType.value === 'SmartAI') {
      return {
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc', '.docx'],
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'text/plain': ['.txt'],
        'text/markdown': ['.md'],
      };
    }
    return {
      'application/pdf': ['.pdf'],
    };
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAcceptedFileTypes(),
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        // Show error message for invalid file type
        GenerateNotification(
          buildNotification({
            title:
              'Invalid file type! Only PDF files are allowed for this assistant type.',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        // Add accepted files to the droppedFiles state
        setDroppedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      }
    },
  });
  return (
    <SliderCard title={title} handleSlide={handleSlide}>
      <SliderCard.Body>
        {isLoader && <Loader />}
        <Form
          onSubmit={handleFormSubmit}
          render={(formRenderProps) => (
            <div className="card-content">
              <div>
                <label>
                  Type <span className={'required-field'}>*</span>
                </label>
                <DropDownList
                  data={filteredAssistantTypes}
                  value={selectedType}
                  onChange={handleTypeChange}
                  textField="text"
                  dataItemKey="value"
                  defaultValue={selectedType}
                  disabled={isUpdate} // Disable the type field on edit
                  className={'!k-mt-1.5'}
                  required={true}
                />
                {errors?.assistantType && (
                  <Error>Assistant Type required!</Error>
                )}
              </div>
              {selectedType.value === 'OpenAI' && (
                <>
                  <Field
                    key={'assistantName'}
                    id={'assistantName'}
                    name={'assistantName'}
                    data={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    label={'Assistant Name'}
                    type={'text'}
                    component={TextInput}
                    optional={false}
                    requiredField={true}
                  />
                  {errors?.assistantName && (
                    <Error>This field cannot be empty</Error>
                  )}
                  <div className={'!k-mt-5'}>
                    <label className={'k-mb-1'}>Templates</label>
                    <DropDownList
                      data={[
                        { name: 'Please Select', description: '' },
                        ...templates,
                      ]}
                      value={selectedOption}
                      onChange={handleDropdownChange}
                      defaultValue={selectedOption}
                      textField="name"
                      dataItemKey="description"
                      className="form-control !k-mt-1.5"
                    />
                  </div>
                  <div className="!k-mt-5">
                    <label>
                      Instructions <span className={'required-field'}>*</span>
                    </label>
                    <textarea
                      value={assistantInstructions}
                      onChange={(e) => setAssistantInstructions(e.target.value)}
                      className="form-control k-display-inline-block k-w-100 k-text-area !k-mt-1"
                      rows={15}
                      required
                    />
                    {errors?.assistantInstructions && (
                      <Error>This field cannot be empty</Error>
                    )}
                  </div>
                  <div className="!k-mt-5">
                    <div {...getRootProps({ className: 'dropzone !k-mt-5' })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    {selectedFiles.length > 0 && (
                      <>
                        <label>Associated Files:</label>
                        <div className="uploaded-files !k-mt-1.5">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="custom-card">
                              <div className="card-body">
                                <span className="file-name">
                                  {file.file_name}
                                </span>
                                <button
                                  className="delete-button k-button-icon k-font-icon k-i-trash"
                                  onClick={() => handleDeleteFile(file.id)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="uploaded-files">
                      {droppedFiles.map((file, index) => (
                        <div key={index} className="custom-card">
                          <div className="card-body">
                            <span className="file-name">{file.name}</span>
                            <button
                              className="delete-button k-button-icon k-font-icon k-i-trash"
                              onClick={() =>
                                setDroppedFiles((prevFiles) =>
                                  prevFiles.filter((_, i) => i !== index)
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {selectedType.value === 'SmartAI' && (
                <>
                  <Field
                    key={'assistantName'}
                    id={'assistantName'}
                    name={'assistantName'}
                    data={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    label={'Assistant Name'}
                    type={'text'}
                    component={TextInput}
                    optional={false}
                    requiredField={true}
                  />
                  {errors?.assistantName && (
                    <Error>{errors.assistantName}</Error>
                  )}
                  <div className="!k-mt-5">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    {errors?.droppedFiles && (
                      <Error>{errors.droppedFiles}</Error>
                    )}
                    {selectedFiles.length > 0 && (
                      <>
                        <label>Associated Files:</label>
                        <div className="uploaded-files !k-mt-1.5">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="custom-card">
                              <div className="card-body">
                                <span className="file-name">
                                  {file?.file_name || file?.filename}
                                </span>
                                <button
                                  className="delete-button k-button-icon k-font-icon k-i-trash"
                                  onClick={() => handleDeleteFile(file.id)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="uploaded-files">
                      {droppedFiles.map((file, index) => (
                        <div key={index} className="custom-card">
                          <div className="card-body">
                            <span className="file-name">{file.name}</span>
                            <button
                              className="delete-button k-button-icon k-font-icon k-i-trash"
                              onClick={() =>
                                setDroppedFiles((prevFiles) =>
                                  prevFiles.filter((_, i) => i !== index)
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {selectedType.value === 'BlueYonder' && (
                <>
                  {selectedType.value === 'BlueYonder' &&
                  !BYResponse &&
                  !isUpdate ? (
                    <>
                      <Field
                        key={'tenantKey'}
                        id={'tenantKey'}
                        name={'tenantKey'}
                        data={tenantKey}
                        onChange={(e) => setTenantKey(e.target.value)}
                        label={'Smart Assistant Tenant Key'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                        requiredField={true}
                      />
                      {errors?.tenantKey && <Error>{errors.tenantKey}</Error>}
                      <Field
                        key={'assistantId'}
                        id={'assistantId'}
                        name={'assistantId'}
                        data={assistantId}
                        onChange={(e) => setAssistantId(e.target.value)}
                        label={'Assistant ID'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                        requiredField={true}
                      />
                      {errors?.assistantId && (
                        <Error>{errors.assistantId}</Error>
                      )}
                    </>
                  ) : (
                    <>
                      <Field
                        key={'assistantName'}
                        id={'assistantName'}
                        name={'assistantName'}
                        data={assistantName}
                        onChange={(e) => setAssistantName(e.target.value)}
                        label={'Assistant Name'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                        requiredField={true}
                      />
                      {errors?.assistantName && (
                        <Error>{errors.assistantName}</Error>
                      )}
                      {isUpdate ? (
                        <div>
                          <Field
                            key={'serverid'}
                            id={'serverid'}
                            name={'serverid'}
                            data={serverid}
                            onChange={(e) => setServerId(e.target.value)}
                            label={'Server ID'}
                            type={'text'}
                            component={TextInput}
                            optional={false}
                            requiredField={true}
                          />
                        </div>
                      ) : (
                        <div className={'!k-mt-5'}>
                          <label className={'k-mb-1'}>
                            Server ID{' '}
                            <span className={'required-field'}>*</span>
                          </label>
                          <DropDownList
                            className="!k-mt-1.5"
                            data={['Please select', ...serverOptions]} // Adding 'Please select' as the first option
                            value={serverid || 'Please select'} // Defaulting to 'Please select' if no server is selected
                            onChange={(e) =>
                              setServerId(
                                e.target.value === 'Please select'
                                  ? ''
                                  : e.target.value
                              )
                            } // Set to empty if 'Please select' is chosen
                            optional={false}
                            requiredField={true}
                          />
                          {errors?.serverid && <Error>{errors.serverid}</Error>}
                        </div>
                      )}
                      <Field
                        key={'username'}
                        id={'username'}
                        name={'username'}
                        data={username}
                        onChange={(e) => setUsername(e.target.value)}
                        label={'Username'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                        requiredField={true}
                      />
                      {errors?.username && <Error>{errors.username}</Error>}
                      <Field
                        key={'password'}
                        id={'password'}
                        name={'password'}
                        data={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label={'Password'}
                        type={'password'}
                        component={TextInput}
                        optional={false}
                        requiredField={true}
                      />
                      {errors?.password && <Error>{errors.password}</Error>}
                    </>
                  )}
                </>
              )}
              {selectedType.value === 'Custom' && (
                <>
                  <Field
                    key={'assistantName'}
                    id={'assistantName'}
                    name={'assistantName'}
                    data={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    label={'Assistant Name'}
                    type={'text'}
                    component={TextInput}
                    optional={false}
                    requiredField={true}
                  />
                  {errors?.assistantName && (
                    <Error>This field cannot be empty</Error>
                  )}
                  <Field
                    key={'assistantUrl'}
                    id={'assistantUrl'}
                    name={'assistantUrl'}
                    data={customUrl}
                    onChange={(e) => setCustomUrl(e.target.value)}
                    label={'URL'}
                    type={'text'}
                    component={TextInput}
                    optional={true}
                  />
                </>
              )}
            </div>
          )}
        />
      </SliderCard.Body>
      <SliderCard.Footer>
        <DialogActionsBar>
          <Button onClick={handleCancel}>Cancel</Button>
          {selectedType.value === 'BlueYonder' && !BYResponse && !isUpdate ? (
            <Button className="ai-button-primary" onClick={handleFormSubmit}>
              Validate
            </Button>
          ) : (
            <Button className="ai-button-primary" onClick={handleFormSubmit}>
              {isUpdate ? 'Update' : 'Create'} Assistant
            </Button>
          )}
        </DialogActionsBar>
      </SliderCard.Footer>
    </SliderCard>
  );
};

export default AssistantForm;
